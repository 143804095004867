import { createApp } from "vue";
import { createPinia } from "pinia";
import { createWebHistory } from "vue-router";

import createRouter from "./routes.js";
import App from "./App.vue";

const router = createRouter(createWebHistory());
const pinia = createPinia();
const app = createApp(App);
app.use(pinia).use(router).mount("#app");
