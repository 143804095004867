import { defineStore } from "pinia";
export const useOptionStore = defineStore("option", {
  state: () => ({
    popupContainer: "",
  }),
  actions: {
    definePopupContainer(container = "") {
      this.popupContainer = this.popupContainer == container ? "" : container;
    },
  },
});
