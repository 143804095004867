<template>
  <div id="about" class="about-us section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 align-self-center">
          <div class="section-heading">
            <h4>Request <em>Demo</em></h4>
            <img src="/assets/images/heading-line-dec.png" alt="" />
            <p>
              Elevate your understanding and propel your business forward.
              Request a demo to experience the efficiency and innovation
              firsthand.
            </p>
          </div>
          <div class="row">
            <div
              class="col-lg-6"
              :class="{
                invalid: Object.keys(api.demoRequestErrors).includes(
                  'workspace'
                ),
              }"
            >
              <input
                type="text"
                class="box-item w-100"
                placeholder="Workspace (e.g demo.digitask.io)"
                v-model="workspace"
              />
              <span
                v-if="Object.keys(api.demoRequestErrors).includes('workspace')"
                class="error"
              >
                {{ api.demoRequestErrors.workspace[0] }}
              </span>
            </div>
            <div
              class="col-lg-6"
              :class="{
                invalid: Object.keys(api.demoRequestErrors).includes(
                  'org_name'
                ),
              }"
            >
              <input
                type="text"
                class="box-item w-100"
                placeholder="Organization Name"
                v-model="org_name"
              />
              <span
                v-if="Object.keys(api.demoRequestErrors).includes('org_name')"
                class="error"
              >
                {{ api.demoRequestErrors.org_name[0] }}
              </span>
            </div>
            <div
              class="col-lg-12"
              :class="{
                invalid: Object.keys(api.demoRequestErrors).includes('name'),
              }"
            >
              <input
                type="text"
                class="box-item w-100"
                placeholder="Full Name"
                v-model="name"
              />
              <span
                v-if="Object.keys(api.demoRequestErrors).includes('name')"
                class="error"
              >
                {{ api.demoRequestErrors.name[0] }}
              </span>
            </div>
            <div
              class="col-lg-6"
              :class="{
                invalid: Object.keys(api.demoRequestErrors).includes('email'),
              }"
            >
              <input
                type="email"
                class="box-item"
                placeholder="Email Address"
                v-model="email"
              />
              <span
                v-if="Object.keys(api.demoRequestErrors).includes('email')"
                class="error"
              >
                {{ api.demoRequestErrors.email[0] }}
              </span>
            </div>
            <div
              class="col-lg-6"
              :class="{
                invalid: Object.keys(api.demoRequestErrors).includes(
                  'contact_number'
                ),
              }"
            >
              <input
                type="text"
                class="box-item"
                placeholder="Contact Number"
                v-model="contact_number"
              />
              <span
                v-if="
                  Object.keys(api.demoRequestErrors).includes('contact_number')
                "
                class="error"
              >
                {{ api.demoRequestErrors.contact_number[0] }}
              </span>
            </div>
            <div class="col-lg-12">
              <div class="gradient-button">
                <a @click="submit()" :disabled="api.demoRequestProcessing">
                  {{
                    api.demoRequestProcessing
                      ? "Loading ..."
                      : "Start 14-Day Free Trial"
                  }}
                </a>
              </div>
              <div>{{ api.demoRequestMessage }}</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="right-image">
            <img src="/assets/images/about-right-dec.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useAPI } from "@/stores/api";
const api = useAPI();
const workspace = ref(""),
  org_name = ref(""),
  name = ref(""),
  email = ref(""),
  contact_number = ref("");
function submit() {
  if (!workspace.value) {
    api.alertMessage("Workspace is required.", "error");
  } else if (!name.value) {
    api.alertMessage("Name is required.", "error");
  } else if (!email.value) {
    api.alertMessage("Email is required.", "error");
  } else if (!contact_number.value) {
    api.alertMessage("Contact number is required.", "error");
  } else {
    api.demoRequest({
      workspace: workspace.value,
      org_name: org_name.value,
      name: name.value,
      email: email.value,
      contact_number: contact_number.value,
    });
  }
}

function resetFormData() {
  workspace.value = "";
  org_name.value = "";
  name.value = "";
  email.value = "";
  contact_number.value = "";
}

watch(
  () => api.demoRequestMessage,
  (newValue) => {
    if (newValue.indexOf("Successfully") !== -1) {
      resetFormData();
    }
  }
);
</script>
