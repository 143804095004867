<template>
  <div id="pricing" class="pricing-tables">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="section-heading">
            <h4>We Have The Best Pre-Order <em>Prices</em> You Can Get</h4>
            <img src="/assets/images/heading-line-dec.png" alt="" />
            <p v-if="api?.pointerData?.workspace">
              Stay on top of your tasks seamlessly! Renew your package for our
              {{ APP_NAME }} ({{ api?.pointerData?.workspace }}) now, ensuring
              uninterrupted productivity and access to enhanced features even
              after your current subscription expires.
            </p>
            <p v-else>
              Secure the future of great deals! Lock in your savings today with
              our unbeatable pre-order prices. Don't just buy, invest wisely –
              choose the best and leave the rest. Your wallet will thank you
              later!
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="pricing-item-regular">
            <span class="price">$12</span>
            <h4>Standard Plan</h4>
            <div class="icon">
              <img src="/assets/images/pricing-table-01.png" alt="" />
            </div>
            <ul>
              <li>Lorem Ipsum Dolores</li>
              <li>20 TB of Storage</li>
              <li class="non-function">Life-time Support</li>
              <li class="non-function">Premium Add-Ons</li>
              <li class="non-function">Fastest Network</li>
              <li class="non-function">More Options</li>
            </ul>
            <div class="border-button">
              <a href="#">Purchase This Plan Now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="pricing-item-pro">
            <span class="price">$25</span>
            <h4>Business Plan</h4>
            <div class="icon">
              <img src="/assets/images/pricing-table-01.png" alt="" />
            </div>
            <ul>
              <li>Lorem Ipsum Dolores</li>
              <li>50 TB of Storage</li>
              <li>Life-time Support</li>
              <li>Premium Add-Ons</li>
              <li class="non-function">Fastest Network</li>
              <li class="non-function">More Options</li>
            </ul>
            <div class="border-button">
              <a href="#">Purchase This Plan Now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="pricing-item-regular">
            <span class="price">$66</span>
            <h4>Premium Plan</h4>
            <div class="icon">
              <img src="/assets/images/pricing-table-01.png" alt="" />
            </div>
            <ul>
              <li>Lorem Ipsum Dolores</li>
              <li>120 TB of Storage</li>
              <li>Life-time Support</li>
              <li>Premium Add-Ons</li>
              <li>Fastest Network</li>
              <li>More Options</li>
            </ul>
            <div class="border-button">
              <a href="#">Purchase This Plan Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAPI } from "@/stores/api";
import { useRoute } from "vue-router";
const route = useRoute();
const api = useAPI();
const APP_NAME = process.env.VUE_APP_NAME;
if (route.params?.pointer_code) {
  api.getPointer(route.params.pointer_code);
}
</script>
