import axios from "axios";
import { defineStore } from "pinia";
import Swal from "sweetalert2";
export const useAPI = defineStore("api", {
  state: () => ({
    origin: process.env.VUE_APP_API_URL,
    demoRequestProcessing: false,
    pointerData: {},
    demoRequestData: [],
    demoRequestErrors: [],
    demoRequestMessage: "",
  }),

  actions: {
    demoRequest(payload) {
      try {
        Swal.fire({
          title: "Are you sure?",
          text: "Unlock tailored solutions for your industry's unique challenges. Request a demo today.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Request a Demo!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.demoRequestProcessing = true;
            this.demoRequestErrors = [];
            this.demoRequestMessage = "";
            axios
              .post(`${this.origin}demo/request`, {
                ...payload,
              })
              .then(() => {
                this.demoRequestMessage =
                  "Demo Request Successfully Submitted - Wait For Next Steps";
                this.alertMessage(this.demoRequestMessage);
                setTimeout(() => {
                  this.demoRequestMessage = "";
                }, 5000);
              })
              .catch((error) => {
                this.demoRequestErrors = error?.response?.data?.errors ?? [];
                this.alertMessage(error?.response?.data?.message, "error");
                setTimeout(() => {
                  this.demoRequestErrors = [];
                }, 60000);
              })
              .finally(() => {
                this.demoRequestProcessing = false;
              });
          }
        });
      } catch (error) {
        this.alertMessage(
          "Something went wrong. Please contact to administer.",
          "error"
        );
      }
    },
    getPointer(code) {
      axios
        .get(`${this.origin}renew/${code}/pointer`)
        .then((response) => {
          this.pointerData = response?.data ?? {};
        })
        .catch((error) => {
          this.alertMessage(error?.response?.data?.message, "error");
        })
        .finally(() => {
          if (!this.pointerData && !this.pointerData.code) {
            window.location.href =
              window.location.protocol + "//" + window.location.host;
          }
        });
    },
    alertMessage(title = "", icon = "success") {
      Swal.fire({
        position: "top-end",
        icon: icon,
        title: title,
        timer: 4000,
        showConfirmButton: false,
        timerProgressBar: true,
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
      });
    },
  },
});
