import { createRouter } from "vue-router";
import WelcomePage from "./components/WelcomePage.vue";
import DemoRequestPage from "./components/DemoRequestPage.vue";
import RenewPackagePage from "./components/RenewPackagePage.vue";

const routes = [
  {
    path: "/",
    component: WelcomePage,
    name: "welcome",
  },
  {
    path: "/demo-request",
    component: DemoRequestPage,
    name: "demo-request",
  },
  {
    path: "/renew/:pointer_code",
    component: RenewPackagePage,
    name: "renew-request",
  },
];

export default function (history) {
  return createRouter({
    history,
    routes,
  });
}
